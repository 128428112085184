import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { REHYDRATE } from 'redux-persist'

import type { RootState } from '../store'
import { Configuration } from '../../graphql/generated/api-graphql'
import { StoreToaster } from '../../components/atoms/Toaster'
import { ToasterType } from '../../components/atoms/Toaster/styled'

import { actionTypes } from './types'

const name = 'app'

type AppState = {
  isInit: boolean
  configuration: Configuration | null
  toaster: StoreToaster | null
  authPushPopin: boolean
}

//
// Initial state
//

const initialState: AppState = {
  isInit: false,
  configuration: null,
  toaster: null,
  authPushPopin: false,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name,
  initialState,
  reducers: {
    init: () => undefined,
    setIsInit: (state, action: actionTypes.setIsInit) => {
      const { isInit } = action.payload
      state.isInit = isInit
    },
    setConfiguration: (state, action: actionTypes.setConfiguration) => {
      state.configuration = action.payload
    },
    setSuccessToaster: (state, action: actionTypes.setSuccessToaster) => {
      const text = action.payload
      state.toaster = {
        text: text ?? '',
        variant: ToasterType.success,
      }
    },
    setInfoToaster: (state, action: actionTypes.setInfoToaster) => {
      const text = action.payload
      state.toaster = {
        text: text ?? '',
        variant: ToasterType.info,
      }
    },
    setErrorToaster: (state, action: actionTypes.setErrorToaster) => {
      const text = action.payload
      state.toaster = {
        text: text ?? '',
        variant: ToasterType.error,
      }
    },
    resetToaster: (state) => {
      state.toaster = null
    },
    setAuthPushPopin: (state, action: actionTypes.setAuthPushPopin) => {
      state.authPushPopin = action.payload
    },
  },
  extraReducers: (builder) =>
    builder.addCase<any, PayloadAction<any>>(
      REHYDRATE,
      (_state, action: PayloadAction<any>) => {
        return { ...(action.payload?.[name] ?? initialState), isInit: false }
      }
    ),
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[name]
const isInit = (state: RootState) => root(state).isInit
const configuration = (state: RootState) => root(state).configuration
const toaster = (state: RootState) => root(state).toaster
const authPushPopin = (state: RootState) => root(state).authPushPopin

export const selectors = {
  isInit,
  configuration,
  toaster,
  authPushPopin,
}
