import styled, { css } from 'styled-components'

import { mq, sizes } from '../../../theme'
import Icon from '../../atoms/Icon'
import Link from '../../atoms/Link'
import NavCard from '../NavCard'
import { CardPicture } from '../NavCard/styled'

export const BurgerNav = styled.div`
  @media ${mq(sizes.desktop)} {
    display: none;
  }
`
export const Button = styled.button`
  background: none transparent;
  color: ${({ theme }) => theme.colors.arapawa};
`
export const BurgerIcon = styled(Icon)`
  width: ${({ width }) => (width ? `${width / 10}rem` : '2.4rem')};
  height: auto;
`
export const Close = styled.button``
export const CloseIcon = styled(Icon)``
export const Navigation = styled.nav`
  position: fixed;
  top: 6.2rem;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndexes.modal - 50};
  overflow: hidden;
  background: ${({ theme }) => theme.colors.linen};
  opacity: 1;
  transition: opacity 200ms ease-in-out, visibility 0ms 0ms;
  &[aria-hidden='true'] {
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease-in-out, visibility 0ms 200ms;
    & ${CardPicture} {
      display: none;
    }
  }
`
export const Panels = styled.div<{ $toLevel2?: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  overflow: visible;
  height: 100%;
`
export const Panel = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden auto;
  background: ${({ theme }) => theme.colors.linen};
  transition: transform 200ms ease-in-out, opacity 100ms ease-in-out,
    visibility 0ms 0ms;
  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transform: translateX(2rem);
    transition: transform 200ms ease-in-out, opacity 100ms ease-in-out,
      visibility 0ms 200ms;
  }
`
const commonMenu = css`
  list-style: none;
  margin: 0;
  padding: 0;
`
const commonItem = css`
  border-top: 0.1rem solid ${({ theme }) => theme.colors.gainsboro};
  &:last-child {
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gainsboro};
  }
`
const commonLink = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  color: ${({ theme }) => theme.colors.bismark};
  background: ${({ theme }) => theme.colors.floralWhite};
  &::before {
    content: ' ';
    position: absolute;
    height: 1.35em;
    width: 0.4rem;
    background: ${({ theme }) => theme.colors.atomicTangerine};
    opacity: 0;
    border-radius: 0.2rem;
  }
  &[aria-current='true'] {
    text-indent: 1.4rem;
    font-weight: bold;
    font-variation-settings: 'wght' 600;
  }
  &[aria-current='true']::before {
    opacity: 1;
  }
  & svg {
    flex: 0 0 auto;
    color: ${({ theme }) => theme.colors.ruby};
  }
`
export const Lvl1Menu = styled.ul`
  ${commonMenu}
  & li {
    ${commonItem}
  }
`
export const Lvl1Link = styled(Link)`
  ${({ theme }) => theme.textStyles.textLarge}
  ${commonLink}
  min-height: 6.4rem;
`
export const Label = styled.p`
  position: relative;
`
export const Back = styled.button`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 1rem;
  background: transparent;
  color: ${({ theme }) => theme.colors.salomie};
`
export const LabelLink = styled(Link)`
  ${({ theme }) => theme.textStyles.titleH4}
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 6.4rem;
  padding: 1rem 5rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.pureWhite};
  background: ${({ theme }) => theme.colors.arapawa};
  & svg {
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
  }
`

export const Lvl2Subtitle = styled.p`
  ${({ theme }) => theme.textStyles.titleH6}
  ${commonLink}
  min-height: 4.8rem;
  color: ${({ theme }) => theme.colors.ruby};
  border-top: 0.1rem solid ${({ theme }) => theme.colors.gainsboro};
  a + &,
  ul + & {
    margin-top: 2rem;
  }
`
export const Lvl2Menu = styled.ul`
  ${commonMenu}
  & li {
    ${commonItem}
  }
`
export const Lvl2Link = styled(Link)`
  ${({ theme }) => theme.textStyles.textMedium}
  ${commonLink}
  min-height: 4rem;
  a + &,
  ul + & {
    margin-top: 2rem;
  }
`
export const Lvl2BottomLink = styled(Link)`
  ${({ theme }) => theme.textStyles.titleH7}
  display: inline-flex;
  align-items: center;
  min-height: 3.2rem;
  margin-top: 0.6rem;
  min-height: 4rem;
  padding: 0 1.6rem;
  color: ${({ theme }) => theme.colors.arapawa};
  &:last-child {
    margin-bottom: 1.2rem;
  }
  & svg {
    margin-left: 0.8rem;
    color: ${({ theme }) => theme.colors.greyChateau};
  }
`
export const Lvl1BottomLink = styled(Lvl2BottomLink)`
  margin-top: 1.2rem;
`
export const Cards = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 2rem;
  padding: 2rem 2rem;
  overflow: auto hidden;
`
export const Card = styled(NavCard)`
  width: 25rem;
  height: 25rem;
`
