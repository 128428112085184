import styled, { css } from 'styled-components'

import { mq, sizes } from '../../../theme'
import Icon from '../../atoms/Icon'
import Link from '../../atoms/Link'
import Picture from '../../atoms/Picture'
import SubNav from '../SubNav'
import { CardPicture } from '../NavCard/styled'

export const MainNav = styled.div`
  position: relative;
  width: 100%;
`
const logo = css`
  position: absolute;
  left: 50%;
  bottom: 100%;
  padding: 0.75rem;
  margin: 0;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.linen};
  transform: translate(-50%, 0.75rem);
  z-index: ${({ theme }) => theme.zIndexes.modal - 1};
  @media ${mq(sizes.desktop)} {
    bottom: 0;
    padding: 2rem;
    transform: translate(-50%, 2rem);
  }

  @media print {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    background: none;
    height: 7rem;
  }
`
export const LogoTitle = styled.h1`
  ${logo};
  & span {
    display: none;
  }
`
export const LogoLink = styled(Link)`
  ${logo}
`
export const LogoPicture = styled(Picture)`
  width: 6rem;
  aspect-ratio: 1/1;
  &,
  & img {
    display: flex;
  }
  & img {
    object-fit: contain;
    width: 100%;
  }
  @media ${mq(sizes.desktop)} {
    width: 11rem;
  }
`
export const Wrapper = styled.nav`
  display: none;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  border-top: 0.1rem solid ${({ theme }) => theme.colors.gainsboro};
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gainsboro};
  overflow: auto hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media ${mq(sizes.tablet)} {
    display: flex;
  }
  @media ${mq(sizes.desktop)} {
    height: 7rem;
    align-items: stretch;
    overflow: visible;
  }

  @media print {
    display: none;
  }
`
export const ScrollIndicator = styled.div<{ $isVisible?: boolean }>`
  position: absolute;
  top: 50%;
  z-index: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 4.2rem;
  width: 5.6rem;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors.bismark};
  pointer-events: none;
  opacity: ${({ $isVisible }) => ($isVisible === true ? 1 : 0)};
  transition: opacity 200ms ease-in-out;
  &:first-child {
    left: 0;
    padding-left: 0.5rem;
    justify-content: flex-start;
    background: linear-gradient(
      90deg,
      #f4f1e8 35.42%,
      rgba(244, 241, 232, 0) 100%
    );
  }
  &:last-child {
    right: 0;
    padding-right: 0.5rem;
    justify-content: flex-end;
    background: linear-gradient(
      270deg,
      #f4f1e8 35.42%,
      rgba(244, 241, 232, 0) 100%
    );
  }
`
export const List = styled.div`
  list-style: none;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: 2.5rem;
  margin: 0 auto;
  padding: 0 2.5rem;
  @media ${mq(sizes.desktop)} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 21rem;
    align-items: stretch;
  }
`
export const Item = styled.div`
  display: flex;
  align-items: stretch;
`
export const SubList = styled.div`
  list-style: none;
  flex: 1 1 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: stretch;
  gap: 2.5rem;
  margin: 0;
  padding: 0;
  @media ${mq(sizes.desktop)} {
    gap: 0;
    li:not(:last-child) > & {
      justify-content: flex-end;
    }
  }
`
export const SubItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const StyledLink = styled(Link)<{ $clickable: boolean }>`
  --padding: 0;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: 4.2rem;
  padding-left: var(--padding);
  padding-right: var(--padding);
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.bismark};
  transition: all 150ms ease-in;
  &[aria-current='true'] {
    color: ${({ theme }) => theme.colors.arapawa};
  }
  &:hover {
    color: ${({ theme }) => theme.colors.arapawa};
  }
  &::after {
    content: '';
    background: ${({ theme }) => theme.colors.arapawa};
    position: absolute;
    bottom: 0;
    left: var(--padding);
    right: var(--padding);
    height: 0.4rem;
    opacity: 0;
    transition: all 150ms ease-in;
  }
  &[aria-current='true']::after {
    opacity: 1;
  }
  @media ${mq(sizes.desktop)} {
    --padding: 1.3rem;
  }
  @media ${mq(sizes.scoping)} {
    --padding: 2.5rem;
  }
  cursor: ${({ $clickable }) =>
    $clickable ? 'pointer' : 'default'} !important;
`
export const Styledicon = styled(Icon)`
  width: ${({ width }) => (width ? `${width / 10}rem` : '2.1rem')};
  height: auto;
  @media ${mq(sizes.desktop)} {
    &:not(:only-child) {
      display: none;
    }
  }
`
export const Label = styled.span`
  ${({ theme }) => theme.textStyles.textSmallStrong}
  @media ${mq(null, sizes.desktop)} {
    &:not(:first-child) {
      ${({ theme }) => theme.textStyles.accessibleHiddenText}
    }
  }
`
export const StyledSubNav = styled(SubNav)`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  visibility: hidden;
  @media ${mq(sizes.desktop)} {
    width: auto;
    visibility: visible;
    opacity: 1;
    transition: opacity 300ms ease-in, transform 200ms ease-in-out,
      visibility 0s 0s;
    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;
      transition: opacity 200ms ease-in, transform 200ms ease-in-out,
        visibility 0s 200ms;
      & ${CardPicture} {
        display: none;
      }
    }
  }
`
