import {
  all,
  call,
  put,
  SagaReturnType,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import type { Client } from '@reachfive/identity-core'
import querystring from 'query-string'

import { actions, selectors } from '..'
import {
  getAuthCookie,
  removeAuthCookie,
  setAuthCookie,
} from '../../helpers/CookieHelpers'
import { reach5, api } from '../../configuration'
import ApiSagas from '../api/sagas'
import * as memberQueries from '../../graphql/Services/Member/queries'
import { Member } from '../../graphql/generated/api-graphql'
import { ApiResponse } from '../api/types/state'
import { getClient } from '../../helpers/ReachFiveHelpers'
import Router from '../../routes/Router'
import tracking from '../../tracking'
import BugsnagHelpers from '../../helpers/BugsnagHelpers'

import { MemberToken } from './types/state'

const DEBUG = api.DEBUG
const log = DEBUG ? console.log : () => null

export default class AuthSagas {
  static *init() {
    const cookie = getAuthCookie()
    const token: SagaReturnType<typeof selectors.auth.token> = yield select(
      selectors.auth.token
    )

    // if auth callback
    const query = querystring.parse(window.location.search)
    const authInProgress = query?.error === '0' || (cookie && !token)
    if (authInProgress) {
      yield put(actions.app.setInfoToaster('toaster_authentification_pending'))
    } else if (query?.error === '1') {
      yield put(actions.app.setErrorToaster('toaster_error'))
    }

    log('AUTH : init with cookie', cookie)
    if (cookie) {
      yield call(AuthSagas.memberByToken, cookie, authInProgress)
    } else if (token) {
      yield put(actions.auth.resetAuth())
    } else {
      yield put(actions.auth.setAuthStatusIsComputed(true))
    }
  }

  static *reach5Client() {
    const defaultLocale: SagaReturnType<typeof selectors.i18n.defaultLocale> =
      yield select(selectors.i18n.defaultLocale)
    const locale: SagaReturnType<typeof selectors.i18n.locale> = yield select(
      selectors.i18n.locale
    )

    const configuration: SagaReturnType<typeof selectors.app.configuration> =
      yield select(selectors.app.configuration)

    const client: Client = yield call(getClient, {
      domain: configuration?.reach5?.domain ?? reach5.DOMAIN,
      clientId: configuration?.reach5?.clientId ?? reach5.CLIENT_ID,
      language: locale ?? defaultLocale,
    })

    return client
  }

  static *login({ payload }: ReturnType<typeof actions.auth.loginRequest>) {
    const client: SagaReturnType<typeof AuthSagas.reach5Client> = yield call(
      AuthSagas.reach5Client
    )

    try {
      yield call(client.loginWithPassword, {
        ...payload,
        auth: {
          redirectUri: api.API_URL + '/nf/auth/callback',
          state: window.location.href?.replace(/\?.*/, ''),
          requireRefreshToken: true,
        },
      })
    } catch (e) {
      console.error('Reach5 Login error', e)
      yield put(actions.auth.loginError(e))
    }
  }

  static *socialLogin({
    payload,
  }: ReturnType<typeof actions.auth.socialLoginRequest>) {
    const client: SagaReturnType<typeof AuthSagas.reach5Client> = yield call(
      AuthSagas.reach5Client
    )

    try {
      yield call(client.loginWithSocialProvider, payload.provider, {
        redirectUri: api.API_URL + '/nf/auth/callback',
        state: window.location.href?.replace(/\?.*/, ''),
        requireRefreshToken: true,
      })
    } catch (e) {
      console.error('Reach5 Login error', e)
    }
  }

  static *memberByToken(token: MemberToken, authInProgress?: boolean) {
    const isTryingToAccessUnauthorizedPage: SagaReturnType<
      typeof selectors.member.isTryingToAccessUnauthorizedPage
    > = yield select(selectors.member.isTryingToAccessUnauthorizedPage)
    log('memberByToken')
    yield put(actions.auth.setToken(token))
    const client: SagaReturnType<typeof AuthSagas.reach5Client> = yield call(
      AuthSagas.reach5Client
    )

    log('client', client)

    const accessToken: SagaReturnType<typeof selectors.auth.accessToken> =
      yield select(selectors.auth.accessToken)

    log('accessToken', accessToken)

    const configuration: SagaReturnType<typeof selectors.app.configuration> =
      yield select(selectors.app.configuration)

    log('configuration', configuration)

    const hasResetPassword: SagaReturnType<
      typeof selectors.auth.hasResetPassword
    > = yield select(selectors.auth.hasResetPassword)

    if (accessToken) {
      try {
        // We need to update the password_updated_at custom field when the user has reset his password to avoid the reset password process to be triggered again
        if (hasResetPassword) {
          yield call(client.updateProfile, {
            accessToken,
            data: {
              customFields: {
                password_updated_at: Math.floor(Date.now() / 1000).toString(),
              },
            },
          })
          yield put(actions.auth.setHasResetPassword(false))
        }
      } catch (e) {
        console.error('Reach5 updateProfile error', e)
      }

      try {
        const rs: Member = yield call(client.getUser, {
          accessToken,
          fields: configuration?.reach5?.fields?.join(',') ?? reach5.FIELDS,
        })
        yield put(actions.auth.setUser(rs))
        yield put(actions.coupons.getCartRequest(null))
        yield call(AuthSagas.loadUserFavorites)
        yield put(actions.auth.setFullAuthPopinOpen(false))

        // Auth process in progress
        if (authInProgress) {
          yield put(
            actions.app.setSuccessToaster('toaster_authentification_success')
          )
          yield put(actions.solo.linkCapriceSession())
          yield call(tracking.authPopin.direct)
          if (isTryingToAccessUnauthorizedPage) {
            yield call(Router.push, isTryingToAccessUnauthorizedPage)
            yield put(actions.member.setIsTryingToAccessUnauthorizedPage(null))
          }
        }
        yield put(actions.auth.setAuthStatusIsComputed(true))
        return
      } catch (e) {
        if (authInProgress) {
          yield put(actions.app.setErrorToaster('toaster_error'))
        }
        console.error(e, token)
        BugsnagHelpers?.notify(
          new Error('Reach5 memberByToken error'),
          (event) => {
            event.addMetadata('reach5', { token, accessToken, error: e })
          }
        )
      }
    } else {
      if (authInProgress) {
        yield put(actions.app.setErrorToaster('toaster_error'))
      }
      console.error('Invalid access token', token)
      BugsnagHelpers?.notify(
        new Error('Reach5 memberByToken error'),
        (event) => {
          event.addMetadata('reach5', {
            token,
            error: 'Invalid access token',
          })
        }
      )
    }
    yield put(actions.auth.resetAuth())
    yield put(actions.coupons.resetCoupon())
    yield put(actions.auth.setAuthStatusIsComputed(true))
  }

  static *register({
    payload,
  }: {
    payload: { email: string; password: string }
  }) {
    const client: SagaReturnType<typeof AuthSagas.reach5Client> = yield call(
      AuthSagas.reach5Client
    )

    try {
      const rs: Member = yield call(client.signup, {
        data: payload,
        redirectUrl: reach5.REDIRECT_URI,
        auth: {
          requireRefreshToken: true,
        },
      })
      log(rs)
      return
    } catch (e) {
      console.error(e)
    }
  }

  static *loadUserFavorites() {
    const rs: ApiResponse<typeof memberQueries.favorites> = yield call(
      ApiSagas.query,
      memberQueries.favorites
    )
    if (rs.data && !rs.errors) {
      yield put(actions.auth.setFavoriteBrands(rs.data.brands))
      yield put(actions.auth.setFavoriteRecipes(rs.data.recipes))
      yield put(actions.auth.setFavoriteArticles(rs.data.articles))
      yield put(actions.auth.setFavoriteFolders(rs.data.folders))
    }
  }

  static *onLogout() {
    yield put(actions.coupons.resetCoupon())
    yield call(ApiSagas.query, memberQueries.memberLogout)
    yield put(actions.auth.resetAuth())
    yield put(actions.member.setHasSeenAccountNewsletterPopin(false))
  }

  static onResetAuth() {
    removeAuthCookie()
  }

  static *onSetToken({ payload }: ReturnType<typeof actions.auth.setToken>) {
    if (payload) {
      setAuthCookie(payload)
    } else {
      yield call(AuthSagas.onResetAuth)
    }
  }

  static *requestPasswordReset({
    payload,
  }: ReturnType<typeof actions.auth.requestPasswordResetRequest>) {
    const client: SagaReturnType<typeof AuthSagas.reach5Client> = yield call(
      AuthSagas.reach5Client
    )

    try {
      yield call(client.requestPasswordReset, {
        email: payload.email,
        redirectUrl: reach5.REQUEST_PASSWORD_RESET_REDIRECT_URI,
      })
    } catch (e) {
      console.error('Reach5 RequestPassword error', e)
    }
    // Always show success message to avoid email fishing
    yield put(actions.auth.requestPasswordResetSuccess(true))
    yield put(actions.app.setSuccessToaster('toaster_request_password_success'))
  }

  static *resetPassword({
    payload,
  }: ReturnType<typeof actions.auth.resetPasswordRequest>) {
    const client: SagaReturnType<typeof AuthSagas.reach5Client> = yield call(
      AuthSagas.reach5Client
    )

    try {
      yield call(client.updatePassword, {
        verificationCode: payload.verification_code,
        email: payload.email,
        password: payload.password,
      })
      yield put(actions.auth.setHasResetPassword(true))
      yield put(actions.auth.resetPasswordSuccess(true))
    } catch (e) {
      console.error('Reach5 ResetPassword error', e)
      yield put(actions.auth.resetPasswordError(e))
      yield put(actions.app.setErrorToaster('toaster_reset_password_error'))
    }
  }

  static *loop() {
    yield all([
      takeLeading(actions.auth.resetAuth, this.onResetAuth),
      takeLatest(actions.auth.loginRequest, this.login),
      takeLatest(actions.auth.socialLoginRequest, this.socialLogin),
      takeLatest(actions.auth.logout, this.onLogout),
      takeLeading(actions.auth.setToken, this.onSetToken),
      takeLeading(
        actions.auth.requestPasswordResetRequest,
        this.requestPasswordReset
      ),
      takeLeading(actions.auth.resetPasswordRequest, this.resetPassword),
    ])
  }
}
