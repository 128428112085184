import React, { FC, useEffect, useId, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { useIsomorphicLayoutEffect } from 'usehooks-ts'

import tracking from '../../../tracking'
import { FormikFormProps } from '../../form/FormikForm'
import { ActionButtonProps } from '../../atoms/ActionButton'
import { PictureProps } from '../../atoms/Picture'
import FormikField, {
  FormikFieldProps,
} from '../../../components/form/FormikField'
import { LinkProps } from '../../atoms/Link'
import { FormMessagesProps } from '../../form/FormMessages'

import * as SC from './styled'

export type HeaderPopinAuthProps = {
  isOpen?: boolean
  heroPopin?: string
  closeButton?: string
  signUpText?: string
  linkSignUp?: LinkProps
  loginSubtitle?: string
  imageProps?: PictureProps
  formikForm?: FormikFormProps
  loginButtonProps?: ActionButtonProps
  signUpButtonProps?: ActionButtonProps
  formikFieldEmailProps?: FormikFieldProps
  formikFieldPasswordProps?: FormikFieldProps
  submitButtonText?: string
  actionFacebookButtonProps?: ActionButtonProps
  actionGoogleButtonProps?: ActionButtonProps
  linkResetPasswordProps?: LinkProps
  onClose?: () => void
  messagesProps?: FormMessagesProps
}

const HeaderPopinAuth: FC<HeaderPopinAuthProps> = (props) => {
  const {
    isOpen,
    closeButton,
    signUpText,
    linkSignUp,
    heroPopin,
    imageProps,
    loginSubtitle,
    onClose,
    formikForm,
    loginButtonProps,
    signUpButtonProps,
    formikFieldEmailProps,
    formikFieldPasswordProps,
    submitButtonText,
    actionFacebookButtonProps,
    actionGoogleButtonProps,
    linkResetPasswordProps,
    messagesProps,
  } = props

  const initalFocusedElement = useRef<Element | HTMLElement | null>(null)
  const ref = useRef<HTMLDivElement | null>(null)
  const title_id = useId()
  const router = useRouter()

  const [isLogin, setIsLogin] = useState(false)

  // check is mounted to display only on mount and not on SSR
  const [stateMounted, setStateMounted] = useState(false)
  useEffect(() => {
    setStateMounted(true)
  }, [])

  // open status in state to control it from inside
  const [stateOpen, setStateOpen] = useState(false)

  useEffect(() => {
    setStateOpen(isOpen ?? false)
  }, [isOpen])

  useEffect(() => {
    if (!stateOpen) {
      setIsLogin(false)
    }
  }, [stateOpen])

  const focusContentOnOpen = () => {
    const focusableEls = ref?.current?.querySelectorAll(
      'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'
    )
    const firstEl = Array.prototype.slice.call(focusableEls)[0]
    firstEl?.focus({ focusVisible: true })
  }

  // store previous focused element and return it on close
  useIsomorphicLayoutEffect(() => {
    if (stateOpen) {
      initalFocusedElement.current = document.activeElement
      if (ref.current) focusContentOnOpen()
    } else if (initalFocusedElement.current) {
      ;(initalFocusedElement.current as HTMLElement)?.focus()
    }
  }, [stateOpen])

  // close the popin on "escape"
  useEffect(() => {
    if (stateOpen === false) return

    const handlePressEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose?.()
      }
    }

    window.addEventListener('keydown', handlePressEscape)
    return () => {
      window.removeEventListener('keydown', handlePressEscape)
    }
  }, [stateOpen, onClose])

  // close the popin on route change
  useEffect(() => {
    if (!router) return
    const close = () => onClose?.()
    router?.events.on('routeChangeStart', close)
    return () => {
      router?.events.off('routeChangeStart', close)
    }
  }, [router, onClose])

  const handleLogin = () => {
    setIsLogin(!isLogin)
    tracking.authPopin.login()
  }

  return stateMounted ? (
    <SC.HeaderPopinAuth
      aria-hidden={!stateOpen}
      role="dialog"
      aria-modal
      ref={ref}
      {...(heroPopin && { 'aria-labelledby': title_id })}
    >
      <SC.Popin>
        <SC.HeroPopin>
          {imageProps && <SC.PicturePopin {...imageProps} />}
          <SC.HeroPopinTitle>{heroPopin}</SC.HeroPopinTitle>
        </SC.HeroPopin>

        <SC.Content>
          {!isLogin && loginButtonProps ? (
            <SC.LoginButton
              onClick={() => handleLogin()}
              {...loginButtonProps}
            />
          ) : (
            formikForm && (
              <>
                <SC.Form {...formikForm}>
                  <SC.AuthFields>
                    {formikFieldEmailProps && (
                      <FormikField {...formikFieldEmailProps} />
                    )}
                    {formikFieldPasswordProps && (
                      <FormikField {...formikFieldPasswordProps} />
                    )}
                  </SC.AuthFields>

                  {linkResetPasswordProps && (
                    <SC.LinkResetPassword {...linkResetPasswordProps} />
                  )}

                  {messagesProps && <SC.Errors {...messagesProps} />}

                  <SC.SubmitButton type="submit">
                    {submitButtonText}
                  </SC.SubmitButton>
                </SC.Form>
                <SC.OtherAuth>
                  {loginSubtitle && <SC.Subtitle>{loginSubtitle}</SC.Subtitle>}
                  {actionFacebookButtonProps && (
                    <SC.FacebookBtn {...actionFacebookButtonProps} />
                  )}
                  {actionGoogleButtonProps && (
                    <SC.GoogleBtn {...actionGoogleButtonProps} />
                  )}
                </SC.OtherAuth>
              </>
            )
          )}
        </SC.Content>

        <SC.SignUp>
          {!isLogin && signUpButtonProps ? (
            <>
              {signUpText && <SC.Text>{signUpText}</SC.Text>}
              <SC.SignUpButton {...signUpButtonProps} />
            </>
          ) : (
            <>{linkSignUp && <SC.LinkSignUp {...linkSignUp} />}</>
          )}
        </SC.SignUp>
        <SC.CloseButton onClick={onClose}>{closeButton}</SC.CloseButton>
      </SC.Popin>
      <SC.Overlay
        aria-label={closeButton ?? 'Close'}
        $isOpen={stateOpen}
        onClick={onClose}
        type="button"
      />
    </SC.HeaderPopinAuth>
  ) : null
}

export default HeaderPopinAuth
