import gql from 'graphql-tag'

import { WediaImageFragment } from './WediaImageFragment'

export const SubNavColumn = gql`
  fragment SubNavColumn on ConfigurationHeaderItem2ColumnsColumn {
    columnLinks {
      title
      links {
        href
        label
        target
      }
      bottomText
      bottomTextUrl
    }
    cards {
      title
      subTitle
      wording
      href
      image {
        ...WediaImageFragment
      }
    }
    bottomText
    bottomTextUrl
  }
  ${WediaImageFragment}
`
