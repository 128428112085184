import styled from 'styled-components'

import { mq, sizes } from '../../../theme'
import Picture from '../../../components/atoms/Picture'
import ActionButton from '../../../components/atoms/ActionButton'
import FormikForm from '../../../components/form/FormikForm'
import Link from '../../../components/atoms/Link'
import FormSubmitButton from '../../../components/form/FormSubmitButton'
import FormMessages from '../../../components/form/FormMessages'

export const HeaderPopinAuth = styled.div``

export const Popin = styled.div`
  position: fixed;
  top: 50%;
  right: 50%;
  z-index: ${({ theme }) => theme.zIndexes.modal};
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  box-shadow: 0 3rem 3rem rgba(0, 0, 0, 0.35);
  min-width: 90%;
  transform: translate(50%, -50%);
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in, visibility 0s 0s;
  ${HeaderPopinAuth}[aria-hidden='true'] & {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in, visibility 0ms 0.3s;
  }
  @media ${mq(sizes.mobile)} {
    position: absolute;
    min-width: 29rem;
    max-width: 29rem;
    top: 9rem;
    right: 2rem;
    transform: initial;
  }

  @media ${mq(sizes.desktop)} {
    top: 12rem;
    right: 3.2rem;
  }
`

export const HeroPopin = styled.div`
  position: relative;
  height: 6rem;
`
export const PicturePopin = styled(Picture)`
  & img {
    border-radius: 1rem 1rem 0 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
export const HeroPopinTitle = styled.p`
  ${({ theme }) => theme.textStyles.titleH6}
  position: absolute;
  top: 2.5rem;
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.pureWhite};
`

export const Form = styled(FormikForm)``
export const AuthFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`
export const Errors = styled(FormMessages)`
  margin: 1rem 0 !important;
`

export const LinkResetPassword = styled(Link)`
  ${({ theme }) => theme.textStyles.textXSmall};
  color: ${({ theme }) => theme.colors.bismark};
  text-decoration: underline;
  width: max-content;
  margin: 0.7rem 0 1rem 0;
`

export const SubmitButton = styled(FormSubmitButton)`
  width: 100%;
`

export const OtherAuth = styled.div`
  text-align: center;
  margin-top: 2rem;
`

export const Subtitle = styled.p`
  ${({ theme }) => theme.textStyles.textMediumStrong}
  color: ${({ theme }) => theme.colors.arapawa};
`

export const FacebookBtn = styled(ActionButton)`
  margin: 2rem 0 1.5rem 0;
  background-color: ${({ theme }) => theme.colors.facebook};
  border-color: ${({ theme }) => theme.colors.facebook};
  width: 100%;

  & span {
    ${({ theme }) => theme.textStyles.textSmallStrong}
    text-transform: inherit;
  }
`
export const GoogleBtn = styled(ActionButton)`
  margin: 1rem 0 0.5rem 0;
  width: 100%;
  & span {
    ${({ theme }) => theme.textStyles.textSmallStrong}
    text-transform: inherit;
  }
`

export const Overlay = styled.button<{ $isOpen?: boolean }>`
  position: fixed;
  background-color: ${({ theme }) => theme.colors.pureBlack};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndexes.modal - 1};
  width: 100%;
  height: 100%;
  opacity: 0.5;
  visibility: visible;
  transition: opacity 0.3s ease-in, visibility 0s 0s;
  &:not(:disabled) {
    cursor: default;
  }
  ${HeaderPopinAuth}[aria-hidden='true'] & {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in, visibility 0ms 0.3s;
  }
  @media ${mq(sizes.mobile)} {
    opacity: 0;
    visibility: visible;
    transition: opacity 0.3s ease-in, visibility 0s 0s;
    ${HeaderPopinAuth}[aria-hidden='true'] & {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease-in, visibility 0ms 0.3s;
    }
  }
`

export const Content = styled.div`
  padding: 1.9rem 1.5rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.chablis};
`

export const LoginButton = styled(ActionButton)`
  width: 100%;
`

export const SignUp = styled.div`
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const Text = styled.p`
  ${({ theme }) => theme.textStyles.titleH7};
  color: ${({ theme }) => theme.colors.arapawa};
  margin-bottom: 1.5rem;
`
export const SignUpButton = styled(ActionButton)`
  width: 100%;
`
export const LinkSignUp = styled(Link)`
  ${({ theme }) => theme.textStyles.textSmall}
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.arapawa};
`

export const CloseButton = styled.button`
  position: absolute;
  bottom: -3.3rem;
  left: 50%;
  transform: translateX(-50%);
  color: ${({ theme }) => theme.colors.pureWhite};
  ${({ theme }) => theme.textStyles.textMedium};
  text-decoration: underline;
  background-color: transparent;

  @media ${mq(sizes.mobile)} {
    display: none;
  }
`
