import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import tracking from 'src/tracking'
import { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import debounce from 'lodash/debounce'

import { Icons } from '../../components/atoms/Icon'
import { HeaderProps } from '../../components/molecules/Header'
import Router, { routes } from '../../routes/Router'
import {
  ConfigurationFooterSocialLink,
  ConfigurationHeader,
  ConfigurationHeaderItem,
} from '../../graphql/generated/api-graphql'
import { NavLink } from '../../components/molecules/MainNav'
import { actions, selectors } from '../../redux'
import { suggestionTransformer } from '../SearchEngine/searchSuggestion'

import useHeaderPopin from './useHeaderPopin'
import { headerColLinksTransformer } from './headerColLinksTransformer'
import { headerCardsTransformer } from './headerCardsTransformer'

const useHeader = (
  data?: ConfigurationHeader,
  socials?: Array<ConfigurationFooterSocialLink>
): HeaderProps => {
  const { t } = useTranslation()
  const [value, setValue] = useState('')

  const dispatch = useDispatch()
  const { headerPopinProps, setAuthOpen } = useHeaderPopin()
  const { data: suggestions } = useSelector(selectors.search.autocomplete)

  const path = useRouter()

  const request = useMemo(
    () =>
      debounce(
        (q: string) =>
          dispatch(
            actions.search.autocompleteRequest({
              q,
            })
          ),
        200
      ),
    [dispatch]
  )

  useEffect(() => {
    if (value?.length > 0) {
      request(value)
    } else {
      dispatch(actions.search.autocompleteReset())
    }
  }, [value, request, dispatch])

  return {
    mainNav: {
      logoImageProps: {
        withHD: true,
        withLazyLoading: false,
        maxWidth: 110,
        width: 110,
        height: 110,
        alt: t('header_alt_logo'),
        images: [
          {
            src: '/static/assets/images/common/logo-110-min.png',
            size: 110,
          },
          {
            src: '/static/assets/images/common/logo-110-min.webp',
            size: 110,
          },
          {
            src: '/static/assets/images/common/logo-220-min.png',
            size: 220,
          },
          {
            src: '/static/assets/images/common/logo-220-min.webp',
            size: 220,
          },
        ],
      },
      title: t('header_alt_logo'),
      backLinkProps:
        path.pathname === '/'
          ? undefined
          : {
              href: Router.getRouteUrl(routes.home),
              label: t('header_homepage'),
              title: t('header_back_to_homepage'),
            },
      navLinkProps: data?.items.map(
        (item: ConfigurationHeaderItem): NavLink => {
          const left = item?.columns?.left
          const right = item?.columns?.right

          const colLinks = [
            ...(left ? left?.columnLinks : []),
            ...(right ? right?.columnLinks : []),
          ]

          const cardsData = [
            ...(left ? left?.cards : []),
            ...(right ? right?.cards : []),
          ]

          return {
            ...(item?.label &&
              item?.href && {
                href: item?.href === '#' ? undefined : item?.href ?? undefined,
                onClick: () => tracking.header.menu(item?.label, 1),
                'aria-current': path.asPath === item.href ? 'true' : undefined,
              }),
            label: item?.label ?? undefined,
            subNavProps:
              colLinks.length > 0 || cardsData.length > 0
                ? {
                    menus:
                      colLinks.length > 0
                        ? headerColLinksTransformer(left, colLinks, path.asPath)
                        : undefined,
                    cards:
                      cardsData.length > 0
                        ? headerCardsTransformer(t, right, cardsData)
                        : undefined,
                  }
                : undefined,
          }
        }
      ),
    },
    search:
      path.pathname !== routes.search.path
        ? {
            search: {
              name: 'search',
              texts: {
                button: t('searchPage_submit_label'),
              },
              value,
              onChange: (e) => setValue(e.target.value),
              onSubmit: (value) => {
                const isSuggestion =
                  typeof value === 'string'
                    ? false
                    : 'label' in value && value?.label && true
                const q =
                  typeof value === 'string'
                    ? value
                    : 'label' in value && value.label
                    ? value.label
                    : ''
                dispatch(
                  actions.search.setSearchDataLayer({
                    search_bar_location: 'header',
                    search_type: isSuggestion ? 'suggestion' : 'direct',
                  })
                )
                Router.push(Router.getRouteUrl(routes.search, { q }))
              },
              'aria-label': 'Search',
              suggestions: suggestions?.map(suggestionTransformer),
            },
            link: {
              label: 'Aller à la page de search',
              href: Router.getRouteUrl(routes.search),
            },
          }
        : undefined,
    skipNav: {
      linksProps: [
        {
          label: t('skip_to_content'),
          href: '#Content',
        },
      ],
    },
    iconsNav: {
      links: [
        {
          link: {
            href: undefined,
            onClick: () => setAuthOpen(true),
            label: t('connect_to_your_account'),
            ariaLabel: t('connect_to_your_account'),
          },
          icon: {
            icon: Icons.user,
          },
        },
      ],
    },
    socials:
      socials && socials.length > 0
        ? {
            linksProps: socials?.map((item) => ({
              linkProps: {
                href: item.link.href,
                target: item.link.target,
                name: item.type,
                title: item.type,
                onClick: () => tracking.social(item.type, 'header'),
              },
              iconProps: {
                icon:
                  item.type === 'FACEBOOK'
                    ? Icons.socialFacebook
                    : item.type === 'INSTAGRAM'
                    ? Icons.socialInstagram
                    : item.type === 'TWITTER'
                    ? Icons.socialX
                    : item.type === 'PINTEREST'
                    ? Icons.socialPinterest
                    : Icons.cheese,
              },
            })),
          }
        : undefined,
    headerPopinProps,
    burger: {
      id: 'BurgerNav',
      texts: {
        open: t('header_burger_open'),
        close: t('header_burger_close'),
      },
      navigation: data?.items.map((item: ConfigurationHeaderItem) => {
        const left = item.columns?.left
        const right = item.columns?.right

        const cardsData = [
          ...(left ? left?.cards : []),
          ...(right ? right?.cards : []),
        ]
        const colLinks = [
          ...(left ? left?.columnLinks : []),
          ...(right ? right?.columnLinks : []),
        ]

        return {
          link: item.label
            ? {
                href: item?.href === '#' ? undefined : item?.href ?? undefined,
                label: item.label,
                'aria-current': path.asPath === item.href ? 'true' : undefined,
              }
            : undefined,
          menus: headerColLinksTransformer(left, colLinks, path.asPath),
          cards: headerCardsTransformer(t, right, cardsData),
        }
      }),
    },
  }
}

export default useHeader
